import { Box, Center, Spinner, Tooltip, VStack } from '@chakra-ui/react';
import { useRouter } from '@/hooks/useRouter';
import { HiLogout } from 'react-icons/hi';
import { useUserContext } from '@/contexts/user-context';
import UserAvatar from '../user/UserAvatar';
import { IconButtonV2 } from '@/components/ui/IconButtonV2';
import Link from '@/components/ui/Link';
import { useContrastScheme } from '@/chakra/themeUtils';
import { useTranslation } from 'react-i18next';

const SidebarSettings = () => {
    const { user } = useUserContext();
    const router = useRouter();
    const contrastScheme = useContrastScheme('secondary.main');
    const { t } = useTranslation(['users', 'common']);
    if (!user) {
        return (
            <Center>
                <Spinner
                    thickness="4px"
                    speed="0.7s"
                    emptyColor="gray.200"
                    color="primary.main"
                    size="xl"
                />
            </Center>
        );
    }

    return (
        <>
            <VStack spacing={2} align="center" w="100%">
                <Tooltip label={t('users:user_settings')} placement="top">
                    <Box>
                        <UserAvatar
                            size="sm"
                            name={`${user.firstName} ${user.lastName}`}
                            as={Link}
                            cursor="pointer"
                            href="/account"
                        />
                    </Box>
                </Tooltip>
                <IconButtonV2
                    color={`${contrastScheme}.800`}
                    _hover={{
                        bg: `${contrastScheme}.100`,
                    }}
                    _active={{
                        bg: `${contrastScheme}.100`,
                    }}
                    onClick={() => router.push('/auth/logout')}
                    size="md"
                    variant="ghost"
                    label={t('common:logout')}
                    IconComponent={HiLogout}
                />
            </VStack>
        </>
    );
};

export default SidebarSettings;

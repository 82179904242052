import { RequiredIndicator } from '@/components/ui/Indicator';
import { isIsoDateString } from '@/utils/date';
import {
    FormControl,
    FormControlProps,
    FormHelperText,
    FormLabel,
    forwardRef,
    Grid,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    InputProps,
    InputRightAddon,
    InputRightElement,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { HiX } from 'react-icons/hi';
import isDate from 'validator/lib/isDate';

const columnGridAreas = `"label"
                        "input"
                        "helper-text"`;

const rowGridAreas = `"label input"
                        "helper-text input"`;

export type InputFieldBaseProps = {
    name?: InputProps['name'];
    value: InputProps['value'];
    type?: InputProps['type'];
    size?: InputProps['size'];
    onChange?: (value: any) => void;
    onClear?: () => void;
    placeholder?: InputProps['placeholder'];
    label?: string;
    isDisabled?: boolean;
    helperText?: React.ReactNode;
    error?: string;
    prefixContent?: React.ReactNode;
    suffixContent?: React.ReactNode;
    formControlProps?: FormControlProps;
    isRequired?: boolean;
    mt?: number;
    isClearable?: boolean;
    direction?: 'row' | 'column';
    rightElement?: React.ReactNode;
    forceOptional?: boolean;
};

const InputFieldBase = forwardRef(
    (
        {
            name,
            value,
            type = 'text',
            onChange,
            onClear,
            error,
            label,
            helperText,
            isRequired,
            prefixContent,
            suffixContent,
            formControlProps = {},
            placeholder,
            mt,
            size,
            isDisabled,
            isClearable,
            direction = 'column',
            rightElement,
            forceOptional,
            ...props
        }: InputFieldBaseProps,
        forwardedRef,
    ) => {
        const handleChange = useCallback(
            (newValue: string) => {
                if (!onChange) {
                    return;
                }
                if (newValue === '') {
                    onChange(null);
                } else if (type === 'number') {
                    onChange(Number(newValue));
                } else if (type === 'date') {
                    onChange(
                        isDate(newValue, { format: 'yyyy-MM-dd' })
                            ? new Date(newValue).toISOString()
                            : newValue,
                    );
                } else {
                    onChange(newValue);
                }
            },
            [onChange, type],
        );

        const handleClear = useCallback(() => {
            if (onClear) {
                onClear();
            } else {
                handleChange?.('');
            }
        }, [handleChange, onClear]);

        const parsedValue = useMemo(() => {
            if (type === 'date' && isIsoDateString(value)) {
                return format(new Date(value), 'yyyy-MM-dd');
            }
            return value;
        }, [type, value]);

        const optionalIndicator = useMemo(() => {
            if (forceOptional && isRequired) {
                return <RequiredIndicator />;
            }

            return <></>;
        }, [forceOptional, isRequired]);

        return (
            <FormControl
                isRequired={!forceOptional && isRequired}
                isInvalid={Boolean(error)}
                mt={mt}
                {...formControlProps}
            >
                <Grid
                    gridTemplateAreas={direction === 'column' ? columnGridAreas : rowGridAreas}
                    {...(direction === 'row' ? { gridTemplateColumns: '3fr 1fr' } : {})}
                >
                    {label && (
                        <FormLabel
                            gridArea="label"
                            mb="1"
                            optionalIndicator={optionalIndicator}
                            requiredIndicator={<RequiredIndicator />}
                        >
                            {label}
                        </FormLabel>
                    )}
                    <InputGroup size={size} gridArea="input">
                        {prefixContent && <InputLeftElement>{prefixContent}</InputLeftElement>}
                        <Input
                            type={type}
                            errorBorderColor="red.400"
                            placeholder={placeholder || label}
                            value={parsedValue ?? ''}
                            onChange={(e) => handleChange(e.target.value)}
                            ref={forwardedRef}
                            isDisabled={isDisabled}
                            {...props}
                        />
                        {suffixContent && <InputRightAddon>{suffixContent}</InputRightAddon>}
                        {rightElement}
                        {isClearable && !isDisabled && (
                            <InputRightElement
                                onClick={handleClear}
                                cursor="pointer"
                                _hover={{
                                    bg: 'blackAlpha.100',
                                }}
                            >
                                <Icon as={HiX} />
                            </InputRightElement>
                        )}
                    </InputGroup>
                </Grid>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
                {error && <FormHelperText color="red.500">{error}</FormHelperText>}
            </FormControl>
        );
    },
);

export default InputFieldBase;

import { t } from 'i18next';
import isEmail from 'validator/lib/isEmail';
import isIBAN from 'validator/lib/isIBAN';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isIsoDateString } from './date';

export enum InputFieldEnum {
    EMAIL = 'email',
    IBAN = 'iban',
    NUMBER = 'number',
    DATE = 'date',
    PHONE_NUMBER = 'tel',
    NAME = 'name',
}

export const validateInput = ({
    value,
    isRequired,
    type,
    forceOptional,
}: {
    value?: string | number;
    isRequired?: boolean;
    type: InputFieldEnum;
    forceOptional?: boolean;
}): string | undefined => {
    if (forceOptional || (!isRequired && (!value || value === ''))) {
        return;
    }
    if (isRequired && (value === '' || value === null || value === undefined)) {
        return t('error:required');
    }
    if (type === InputFieldEnum.NUMBER && (typeof value != 'number' || isNaN(value))) {
        return t('error:should_be_number');
    }
    if (type === InputFieldEnum.EMAIL && (typeof value != 'string' || !isEmail(value))) {
        return t('error:wrong_email_format');
    }
    if (type === InputFieldEnum.IBAN && (typeof value != 'string' || !isIBAN(value))) {
        return t('error:wrong_iban_format');
    }
    if (type === InputFieldEnum.DATE && (typeof value != 'string' || !isIsoDateString(value))) {
        return t('error:wrong_date_format');
    }
    if (
        type === InputFieldEnum.PHONE_NUMBER &&
        (typeof value != 'string' || !isValidPhoneNumber(value))
    ) {
        return t('error:wrong_phone_number_format');
    }
};

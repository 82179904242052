import { useRouter } from '@/hooks/useRouter';
import { Box, BoxProps, Center, Icon, Text, VStack } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import Link from '../Link';
import { useState } from 'react';
import { useContrastScheme } from '@/chakra/themeUtils';

interface SidebarLinkProps {
    href: string;
    label: string;
    icon: IconType;
    activeIcon: IconType;
    active?: boolean;
}

const SidebarLink = ({
    active,
    label,
    href,
    icon,
    activeIcon,
    ...props
}: SidebarLinkProps & BoxProps) => {
    const { isLocatedIn } = useRouter();
    const tmp = isLocatedIn(href);

    const isActive = active !== undefined ? active : tmp;
    const [isHovered, setIsHovered] = useState(false);
    const contrastScheme = useContrastScheme('secondary.main');
    return (
        <VStack
            as={Link}
            key={label}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            borderRadius="md"
            width="100%"
            href={href}
            spacing={0}
            alignItems="center"
            position="relative"
            {...props}
        >
            <Center
                w="38px"
                h="38px"
                borderRadius="md"
                transition="color, background 0.15s"
                color={isActive ? `${contrastScheme}.900` : `${contrastScheme}.800`}
                background={
                    isActive
                        ? `${contrastScheme}.300`
                        : isHovered
                        ? `${contrastScheme}.400`
                        : undefined
                }
            >
                <Icon
                    transform={isHovered ? 'scale(1.1)' : 'scale(1)'}
                    transition="transform 0.15s"
                    fontSize="24px"
                    as={isActive ? activeIcon : icon}
                />
            </Center>
            <Box px={2}>
                <Text
                    sx={{ '::first-letter': { textTransform: 'capitalize' } }}
                    align="center"
                    fontWeight="medium"
                    fontSize="sm"
                    color={isActive ? `${contrastScheme}.900` : `${contrastScheme}.800`}
                >
                    {label}
                </Text>
            </Box>
        </VStack>
    );
};

export default SidebarLink;

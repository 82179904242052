import React, { ReactNode, useMemo } from 'react';
import { Box, Center, Flex, Spinner } from '@chakra-ui/react';
import Sidebar, { DESKTOP_SIDEBAR_WIDTH } from '../ui/sidebar/Sidebar';
import useIsMobile from '@/hooks/useIsMobile';
import { useSession } from 'next-auth/react';
import { useDevEnv } from '@/utils/dev-env';

const ShellLayout = ({ children }: { children: ReactNode }) => {
    const isMobile = useIsMobile();
    const { status } = useSession();
    const devEnv = useDevEnv();

    const envColorScheme = useMemo(
        () => (devEnv === 'local' ? 'orange' : devEnv === 'staging' ? 'pink' : undefined),
        [devEnv],
    );
    const envText = useMemo(
        () =>
            devEnv === 'local' ? 'Local env' : devEnv === 'staging' ? 'Données de test' : undefined,
        [devEnv],
    );

    if (status !== 'authenticated') {
        return (
            <Center bgColor="gray.100" w="100vw" h="100vh">
                <Spinner color="gray.600" size="xl" />
            </Center>
        );
    }

    return (
        <Flex flexDirection={{ base: 'column', md: 'row' }} minH="100vh">
            <Sidebar />
            <Flex
                marginLeft={isMobile ? '0px' : DESKTOP_SIDEBAR_WIDTH}
                flexDirection="column"
                flex="1"
                bg="secondary.main"
                transition="background 0.2s ease-in-out"
                py={isMobile ? 0 : 2}
                pr={isMobile ? 0 : 2}
                maxWidth={`calc(100vw - ${isMobile ? '0px' : DESKTOP_SIDEBAR_WIDTH})`}
                overflowX="hidden"
            >
                <Box
                    py={isMobile ? 4 : 8}
                    px={isMobile ? 4 : 8}
                    overflowX="hidden"
                    position="relative"
                    bg="white"
                    borderRadius="md"
                    borderTopWidth={devEnv ? '2px' : 0}
                    borderColor={`${envColorScheme}.200`}
                    height="calc(100vh - 16px)"
                    overflowY="auto"
                    maxWidth={`calc(100vw - ${isMobile ? '0px' : DESKTOP_SIDEBAR_WIDTH})`}
                >
                    {devEnv && (
                        <Box
                            px={2}
                            bg={`${envColorScheme}.200`}
                            color={`${envColorScheme}.600`}
                            position="fixed"
                            top={isMobile ? '64px' : 2}
                            borderRadius="full"
                            left="50%"
                            transform="translateX(-50%)"
                            fontSize="sm"
                            textAlign="center"
                            fontWeight="medium"
                            zIndex={1}
                        >
                            {envText}
                        </Box>
                    )}
                    {children}
                </Box>
            </Flex>
        </Flex>
    );
};

export default ShellLayout;
